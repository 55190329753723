var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth",
    [
      _c(
        "a-row",
        {
          staticClass: "ant-row-flex bg",
          staticStyle: { height: "100vh" },
          attrs: { type: "flex", justify: "center", align: "middle" }
        },
        [
          _c(
            "a-col",
            { attrs: { xs: 22, sm: 14, md: 8 } },
            [
              _vm.errors
                ? _c("a-alert", {
                    staticStyle: { "margin-bottom": "30px" },
                    attrs: {
                      type: "error",
                      message: "Email ou mot de passe erroné",
                      banner: ""
                    }
                  })
                : _vm._e(),
              _c(
                "a-form",
                {
                  staticStyle: {
                    "background-color": "#ffffffa1",
                    padding: "5%"
                  },
                  attrs: { form: _vm.form },
                  on: { submit: _vm.handleSubmit }
                },
                [
                  _c("h1", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("Réinitialiser mot de passe")
                  ]),
                  _c(
                    "a-form-item",
                    _vm._b({}, "a-form-item", _vm.formItemLayout, false),
                    [
                      _c(
                        "a-input",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "email",
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: "Email est obligatoire!"
                                    },
                                    {
                                      pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                                      message:
                                        "Le format de l'email n'est pas valide "
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n              'email',\n              {\n                rules: [\n                  { required: true, message: 'Email est obligatoire!' },\n                  {\n                    pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$/,\n                    message: 'Le format de l\\'email n\\'est pas valide ',\n                  },\n                ],\n              },\n            ]"
                            }
                          ],
                          attrs: { placeholder: "Email" }
                        },
                        [
                          _c("a-icon", {
                            staticStyle: { color: "rgba(0,0,0,.25)" },
                            attrs: { slot: "prefix", type: "mail" },
                            slot: "prefix"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b({}, "a-form-item", _vm.formItemLayout, false),
                    [
                      _c(
                        "a-input-password",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "password",
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: "Mot de passe est obligatoire!"
                                    },
                                    {
                                      pattern: /(?=.*\d.*)(?=.*[a-z].*).{8,20}/,
                                      message:
                                        "Il faut que  mot de passe est au moins 8 charactéres, qui ccontient des lettres  minuscule,digit!"
                                    },

                                    { validator: this.validateToNextPassword }
                                  ]
                                }
                              ],
                              expression:
                                "[\n              'password',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Mot de passe est obligatoire!',\n                  },\n                  {\n                    pattern: /(?=.*\\d.*)(?=.*[a-z].*).{8,20}/,\n                    message:\n                      'Il faut que  mot de passe est au moins 8 charactéres, qui ccontient des lettres  minuscule,digit!',\n                  },\n\n                  { validator: this.validateToNextPassword },\n                ],\n              },\n            ]"
                            }
                          ],
                          attrs: {
                            placeholder: "Mot de passe",
                            type: "password"
                          }
                        },
                        [
                          _c("a-icon", {
                            staticStyle: { color: "rgba(0,0,0,.25)" },
                            attrs: { slot: "prefix", type: "lock" },
                            slot: "prefix"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b({}, "a-form-item", _vm.formItemLayout, false),
                    [
                      _c(
                        "a-input-password",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "password_confirmation",
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message:
                                        "Confirmation Mot de passe est obligatoire!"
                                    },
                                    {
                                      validator: _vm.compareToFirstPassword
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n              'password_confirmation',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Confirmation Mot de passe est obligatoire!',\n                  },\n                  {\n                    validator: compareToFirstPassword,\n                  },\n                ],\n              },\n            ]"
                            }
                          ],
                          attrs: {
                            type: "password",
                            placeholder: "Confirmer mot de passe"
                          },
                          on: { blur: _vm.handleConfirmBlur }
                        },
                        [
                          _c("a-icon", {
                            staticStyle: { color: "rgba(0,0,0,.25)" },
                            attrs: { slot: "prefix", type: "lock" },
                            slot: "prefix"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b({}, "a-form-item", _vm.tailFormItemLayout, false),
                    [
                      _c(
                        "a-spin",
                        { attrs: { spinning: _vm.spinning } },
                        [
                          _c(
                            "a-button",
                            {
                              staticClass: "regiter-form-button",
                              attrs: { type: "primary", htmlType: "submit" }
                            },
                            [_vm._v("Réinitialiser\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }